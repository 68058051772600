import React from "react";

function TestInformation({
  testTitle,
  onTestTitleChange,
  selectedClassLevel,
  selectedSubject,
  onSelectedClassLevelChange,
  onSelectedSubjectChange,
  onNext,
}) {
  return (
    <div className="cbt-screen-main">
      <div className="container">
        <div className="form-group form-inline">
          <div className="my-5">
            <h1>Test Information</h1>
          </div>
          <label htmlFor="testTitle" className="mr-2 my-2">
            <h4>Test Title</h4>
          </label>
          <input
            type="text"
            className="form-control custom-input-width"
            id="testTitle"
            value={testTitle}
            onChange={onTestTitleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="selectClassLevel" className="mr-2 my-2">
            <h4>Select Class Level</h4>
          </label>
          <select
            className="form-control custom-input-width"
            id="selectClassLevel"
            value={selectedClassLevel}
            onChange={onSelectedClassLevelChange}
          >
            <option value="Entry">Entry</option>
            <option value="JSS1">JSS1</option>
            <option value="JSS2">JSS2</option>
            <option value="JSS3">JSS3</option>
            <option value="SSS1">SSS1</option>
            <option value="SSS2">SSS2</option>
            <option value="SSS3">SSS3</option>
            <option value="Others">Others</option>
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="selectedSubject" className="mr-2 my-2">
            <h4>Select Subject</h4>
          </label>
          <select
            className="form-control custom-input-width"
            id="selectedSubject"
            value={selectedSubject}
            onChange={onSelectedSubjectChange}
          >
            <option value="">- Select -</option>
            <option value="Mathematics">Mathematics</option>
            <option value="English">English</option>
            <option value="Science">Science</option>
            <option value="Geography">Geography</option>
          </select>
        </div>
        <button
          className="btn btn-primary my-2"
          style={{ width: "15vh" }}
          onClick={onNext}
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default TestInformation;
