import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CBTQuestionsScreen from "./screens/CBTQuestionsScreen";
import SetQuestionsScreen from "./screens/SetQuestionsScreen";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<CBTQuestionsScreen />} />
        <Route exact path="/set-question" element={<SetQuestionsScreen />} />
        <Route path="*" element={<p>Path not resolved</p>} />
      </Routes>
    </Router>
  );
}

export default App;
