import React from "react";

function SetQuestionTopBar({ testTitle, handleSubmitClick }) {
  return (
    <div className="top-bar">
      <h3 className="test-title">{testTitle}</h3>
      <div className="btn sub-btn" onClick={handleSubmitClick}>
        Submit
      </div>
    </div>
  );
}

export default SetQuestionTopBar;
