import React, { useState } from "react";

function QuestionList({
  numberOfQuestions,
  onQuestionClick,
  selectedQuestion,
}) {
  const questionNumbers = Array.from(
    { length: numberOfQuestions },
    (_, index) => index + 1
  );
  //const [selectedQuestion, setSelectedQuestion] = useState(1);

  return (
    <div className="sidebar">
      <ul className="nav flex-column">
        {questionNumbers.map((number) => (
          <li key={number} className="nav-item" style={{ background: "" }}>
            <div
              className={`nav-link ${
                selectedQuestion === number ? "selected" : ""
              }`}
              onClick={() => onQuestionClick(number)}
            >
              Question {number}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default QuestionList;
