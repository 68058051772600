import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import TestInformation from "../components/TestInformation";
import TestConfiguration from "../components/TestConfiguration";
import CustomPopup from "../components/CustomPopup";
import "./CBTQuestionsScreen.css";

function CBTQuestionsScreen() {
  const [currentSection, setCurrentSection] = useState(1);
  const [testTitle, setTestTitle] = useState("");
  const [selectedClassLevel, setSelectedClassLevel] = useState("-");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [timeAllowed, setTimeAllowed] = useState(0);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [noOfOptions, setNoOfOptions] = useState(3);

  const [popupStatus, setPopupStatus] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const [testId, setTestId] = useState("");
  const [openNextScreen, setOpenNextScreen] = useState(false);

  const navigate = useNavigate();

  const closePopup = () => {
    setPopupStatus(false);
    setPopupMessage("");
    if (openNextScreen) {
      setOpenNextScreen(false);
      navigate(`/set-question?testId=${testId}`);
    }
  };

  function handleNext() {
    if (testTitle != null && testTitle !== "" && testTitle !== " ") {
      if (
        selectedSubject !== "" &&
        selectedSubject !== " " &&
        selectedSubject != null
      ) {
        setCurrentSection(currentSection + 1);
      } else {
        setPopupStatus(true);
        setPopupMessage("Select a Subject");
      }
    } else {
      setPopupStatus(true);
      setPopupMessage("Enter Test Title");
    }
  }

  function handleback() {
    setCurrentSection(currentSection - 1);
  }

  function handleTestTitleChange(e) {
    setTestTitle(e.target.value);
  }

  function handleSelectedSubjectChange(e) {
    setSelectedSubject(e.target.value);
  }

  function handleSelectedClassLevelChange(e) {
    setSelectedClassLevel(e.target.value);
  }

  function handleNoOfOptionsChange(e) {
    setNoOfOptions(e.target.value);
  }

  function handleNumberOfQuestionsChange(e) {
    setNumberOfQuestions(Number(e.target.value));
  }

  function handleOnTimeAllowedchange(e) {
    setTimeAllowed(Number(e.target.value));
  }

  const classLevelMap = {
    Entry: 1,
    JSS1: 2,
    JSS2: 3,
    JSS3: 4,
    SSS1: 5,
    SSS2: 6,
    SSS3: 7,
    Others: 8,
  };

  function getClassLevelId(classLevelText) {
    return classLevelMap[classLevelText] || 0;
  }

  function handleCreateLogic() {
    if (timeAllowed != null && timeAllowed !== 0) {
      if (numberOfQuestions != null && numberOfQuestions !== 0) {
        createCBTTest();
      } else {
        setPopupStatus(true);
        setPopupMessage("Number of questions cannot be 0");
      }
    } else {
      setPopupStatus(true);
      setPopupMessage("Time allowed cannot be 0");
    }
  }

  function createCBTTest() {
    const classLevelId = getClassLevelId(selectedClassLevel);

    const testInfo = {
      testTitle,
      selectedClassLevel: classLevelId,
      selectedSubject,
      timeAllowed,
      numberOfQuestions,
      noOfOptions,
    };

    fetch("https://teachers.cbt.thecribbers.ng/backend/api/php/create_test.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(testInfo),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.status === "success") {
          setTestId(data.testId);
          setOpenNextScreen(true);
          setPopupStatus(true);
          setPopupMessage(data.message);
        } else {
          setPopupStatus(true);
          setPopupMessage(data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      <div className="container ">
        <div style={{ background: "#042855", color: "white" }}>
          <h1 className="heading-text">Create a new Test</h1>
        </div>
        {currentSection === 1 && (
          <TestInformation
            testTitle={testTitle}
            onTestTitleChange={handleTestTitleChange}
            selectedClassLevel={selectedClassLevel}
            selectedSubject={selectedSubject}
            onSelectedClassLevelChange={handleSelectedClassLevelChange}
            onSelectedSubjectChange={handleSelectedSubjectChange}
            onNext={handleNext}
          />
        )}
        {currentSection === 2 && (
          <TestConfiguration
            timeAllowed={timeAllowed}
            onTimeAllowedchange={handleOnTimeAllowedchange}
            numberOfQuestions={numberOfQuestions}
            onNumberOfQuestionsChange={handleNumberOfQuestionsChange}
            noOfOptions={noOfOptions}
            onNoOfOptionsChange={handleNoOfOptionsChange}
            onNext={handleCreateLogic}
            onBack={handleback}
          />
        )}
      </div>
      {popupStatus && (
        <CustomPopup message={popupMessage} onClose={closePopup} />
      )}
    </>
  );
}

export default CBTQuestionsScreen;
