import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import QuestionList from "../components/QuestionList";
import QuestionForm from "../components/QuestionForm";
import "./CBTQuestionsScreen.css";
import SetQuestionTopBar from "../components/SetQuestionTopBar";
import CustomPopup from "../components/CustomPopup";

function SetQuestionsScreen() {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [openNextScreen, setOpenNextScreen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(1);
  const [testDetails, setTestDetails] = useState(null);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const testId = searchParams.get("testId");

  const navigate = useNavigate();

  useEffect(() => {
    fetch(
      `https://teachers.cbt.thecribbers.ng/backend/api/php/get_test_details.php?testId=${testId}`
    )
      .then((response) => response.json())
      .then((data) => {
        setTestDetails(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [testId]);

  function handleQuestionClick(questionNumber) {
    setSelectedQuestion(questionNumber);
  }

  const closePopup = () => {
    setShowPopup(false);
    setPopupMessage("");
    if (openNextScreen) {
      setOpenNextScreen(false);
      navigate(`/`);
    }
  };

  const handleSubmitClick = () => {
    const requestData = {
      testId: testId,
    };
  
    fetch("https://teachers.cbt.thecribbers.ng/backend/api/php/submitTest.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setPopupMessage(data.error); 
        } else {
          setOpenNextScreen(true);
          setPopupMessage(`${testDetails.testTitle} has been submitted for review`);
        }
        setShowPopup(true);
      })
      .catch((error) => {
        setPopupMessage("Failed to submit test. Please try again later.");
        setShowPopup(true);
        console.error("API Error:", error);
      });
  };
  

  function renderQuestionForms() {
    if (testDetails) {
      const { numberOfQuestions, numberOfOptions } = testDetails;
      return Array.from({ length: numberOfQuestions }, (_, index) => (
        <QuestionForm
          key={index}
          questionNumber={index + 1}
          isVisible={selectedQuestion === index + 1}
          numberOfOptions={numberOfOptions}
          testId={testId}
        />
      ));
    }
    return null;
  }

  return (
    <>
      {showPopup && <CustomPopup message={popupMessage} onClose={closePopup} />}
      <div className="set-main-screen">
        {testDetails ? (
          <SetQuestionTopBar
            testTitle={testDetails.testTitle}
            handleSubmitClick={handleSubmitClick}
          />
        ) : (
          <SetQuestionTopBar testTitle={"Loading..."} />
        )}
        <div className="container">
          <div className="row">
            <div className="col-3 sidebar-container">
              <QuestionList
                numberOfQuestions={testDetails?.numberOfQuestions || 0}
                onQuestionClick={handleQuestionClick}
                selectedQuestion={selectedQuestion}
              />
            </div>
            <div className="col-9 content-container">
              <div className="scrollable-content">
                {testDetails ? (
                  renderQuestionForms()
                ) : (
                  <div className="loading-container">
                    <div className="loading-spinner"></div>
                    <p>Loading...</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SetQuestionsScreen;
