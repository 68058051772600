import React, { useState, useEffect } from "react";
import CustomPopup from "./CustomPopup";

function QuestionForm({ questionNumber, isVisible, numberOfOptions, testId }) {
  const [isSaving, setIsSaving] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");

  const questionKey = `${testId}-${questionNumber}`;

  const [hasDiagram, setHasDiagram] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageFile, setIsImageFile] = useState(false);
  const [options, setOptions] = useState(
    Array.from({ length: numberOfOptions }, (_, index) => ({
      optionText: " ",
      isAnswer: false,
      optionKey: `${questionKey}-${index}`,
    }))
  );
  const [questionInput, setQuestionInput] = useState("");

  useEffect(() => {
    if (questionKey) {
      fetchQuestionAndOptionsDataFromDatabase();
    }
  }, [questionKey]);

  function fetchQuestionAndOptionsDataFromDatabase() {
    fetch(
      `https://teachers.cbt.thecribbers.ng/backend/api/php/fetchQuestionAndOptionsData.php?questionKey=${questionKey}&numberOfOptions=${numberOfOptions}`
    )
      .then((response) => response.json())
      .then((data) => {
        setQuestionInput(data.questionText);
        setHasDiagram(data.hasDiagram);
        if (data.hasDiagram) {
          setSelectedImage(data.diagramUrl);
        }
        setOptions(data.options);
      })
      .catch((error) => {
        console.error("Error fetching question and options data:", error);
      });
  }

  const handleDiagramChange = () => {
    setHasDiagram(!hasDiagram);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const maxSizeInBytes = 512 * 1024;
      if (file.size > maxSizeInBytes) {
        setPopupMessage("Image size should not exceed 512KB.");
        setShowPopup(true);
        setSelectedImage(null);
        setIsImageFile(false);
      } else {
        setSelectedImage(file);
        setIsImageFile(true);
      }
    } else {
      setSelectedImage(null);
      setIsImageFile(false);
    }
  };

  function handleOptionChange(e, index) {
    const updatedOptions = [...options];
    updatedOptions[index] = {
      ...updatedOptions[index],
      optionText: e.target.value,
      optionKey: `${questionKey}-${index}`,
    };
    setOptions(updatedOptions);
  }

  async function handleSave() {
    if (
      !testId ||
      !questionInput?.trim() ||
      !questionKey?.trim() ||
      hasDiagram === null
    ) {
      setPopupMessage(
        "Please provide valid values for Test ID, Question Text, Has Diagram, and Question Key."
      );
      setShowPopup(true);
      return;
    }

    if (hasDiagram && !selectedImage) {
      setPopupMessage("Please provide a valid Image for the Diagram");
      setShowPopup(true);
      return;
    }

    const hasEmptyOption = options.some((option) => !option.optionText.trim());
    if (hasEmptyOption) {
      setPopupMessage("Please provide valid values for all Option Texts.");
      setShowPopup(true);
      return;
    }

    const answerCount = options.reduce(
      (count, option) => (option.isAnswer ? count + 1 : count),
      0
    );
    if (answerCount === 0 || answerCount > 1) {
      setPopupMessage("Please select exactly one correct answer.");
      setShowPopup(true);
      return;
    }

    setIsSaving(true);
    setPopupMessage("Saving question...");
    setShowPopup(true);

    try {
      const questionData = {
        testId,
        questionText: questionInput,
        hasDiagram,
        diagramUrl: hasDiagram ? selectedImage : "",
        questionKey,
        options,
      };

      const questionExists = !!questionKey.trim();

      await createQuestionAndOptionsInBackend(questionData);
      setPopupMessage("Question saved successfully!");
    } catch (error) {
      setPopupMessage("Failed to save question. Please try again later.");
    } finally {
      setIsSaving(false);
      setShowPopup(true);
    }
  }

  async function createQuestionAndOptionsInBackend(questionData) {
    try {
      const formData = new FormData();

      formData.append("testId", questionData.testId);
      formData.append("questionText", questionData.questionText);
      formData.append("hasDiagram", questionData.hasDiagram);
      if (questionData.hasDiagram) {
        formData.append("diagram", questionData.diagramUrl);
      }
      formData.append("questionKey", questionData.questionKey);
      formData.append("options", JSON.stringify(questionData.options));

      const response = await fetch(
        "https://teachers.cbt.thecribbers.ng/backend/api/php/createQuestionAndOptions.php",
        {
          method: "POST",
          mode: "cors",
          body: formData,
        }
      );
      if (!response.ok) {
        throw new Error(
          "Failed to create question and options in the backend."
        );
      }
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error("Failed to create question and options in the backend.");
    }
  }

  function handleQuestionChange(e) {
    setQuestionInput(e.target.value);
  }

  const alphabetLabels = "abcdefghijklmnopqrstuvwxyz".toUpperCase();
  const optionInputs = options.map((option, index) => (
    <div key={index} className="d-flex align-items-center">
      <label className="mr-2" style={{ marginRight: "10px" }}>
        {alphabetLabels[index]}
      </label>
      <div className="form-check mr-2">
        <input
          type="radio"
          className="form-check-input"
          id={`option${questionNumber}_${index}`}
          checked={option.isAnswer}
          onChange={() => handleOptionSelect(index)}
        />
      </div>
      <input
        type="text"
        className="form-control my-1"
        id={`optionText${questionNumber}_${index}`}
        value={option.optionText}
        style={{ width: "50vh" }}
        onChange={(e) => handleOptionChange(e, index)}
      />
    </div>
  ));

  function handleOptionSelect(selectedIndex) {
    const updatedOptions = options.map((option, index) => ({
      ...option,
      isAnswer: index === selectedIndex,
    }));
    setOptions(updatedOptions);
  }

  return (
    <>
      {showPopup && (
        <CustomPopup
          message={popupMessage}
          onClose={() => setShowPopup(false)}
        />
      )}
      <div className={`question-form ${isVisible ? "" : "d-none"}`}>
        <h2>Question {questionNumber}</h2>
        <div className="form-group">
          <label htmlFor={`question${questionNumber}`}>Question</label>
          <textarea
            className="form-control"
            id={`question${questionNumber}`}
            rows={5}
            value={questionInput}
            onChange={handleQuestionChange}
          />
        </div>
        <div className="form-check my-2">
          <input
            type="checkbox"
            className="form-check-input "
            id={`diagram${questionNumber}`}
            checked={hasDiagram}
            onChange={handleDiagramChange}
          />
          <label
            className="form-check-label "
            htmlFor={`diagram${questionNumber}`}
          >
            Has Diagram
          </label>
        </div>
        {hasDiagram && (
          <>
            <div className="form-group">
              <div
                className="d-flex align-items-center"
                style={{ marginBottom: "10px" }}
              >
                <div style={{ marginRight: "10px" }}>
                  <label htmlFor={`image${questionNumber}`}>Image</label>
                </div>
                <div style={{ marginRight: "10px" }}>
                  <input
                    type="file"
                    className="form-control-file"
                    id={`image${questionNumber}`}
                    onChange={handleImageChange}
                  />
                </div>
              </div>
            </div>
            {selectedImage && (
              <div
                className="sizable-box"
                style={{
                  marginBottom: "10px",
                }}
              >
                {isImageFile ? (
                  <img
                    src={URL.createObjectURL(selectedImage)}
                    alt="Selected"
                    className="selected-image"
                  />
                ) : (
                  <img
                    src={selectedImage}
                    alt="Selected"
                    className="selected-image"
                  />
                )}
              </div>
            )}
          </>
        )}
        <div className="form-group">
          <label htmlFor={`options${questionNumber}`}>Options</label>
          {optionInputs}
        </div>
        <div className="my-2">
          <div
            className="btn btn-primary"
            onClick={handleSave}
            disabled={isSaving}
          >
            {isSaving ? "Saving..." : "Save"}
          </div>
        </div>
      </div>
    </>
  );
}

export default QuestionForm;
