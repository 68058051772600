import React from "react";

function TestConfiguration({
  timeAllowed,
  onTimeAllowedchange,
  numberOfQuestions,
  onNumberOfQuestionsChange,
  noOfOptions,
  onNoOfOptionsChange,
  onNext,
  onBack,
}) {
  return (
    <div className="cbt-screen-main">
      <div className="container">
        <div className="form-group form-inline">
          <div className="my-3">
            <h2>Test Configuration</h2>
          </div>
          <label htmlFor="timeAllowed" className="mr-2 my-2">
            {" "}
            <h5>Time Allowed (minutes)</h5>{" "}
          </label>
          <input
            type="number"
            className="form-control custom-input-width"
            id="timeAllowed"
            value={timeAllowed}
            onChange={onTimeAllowedchange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="numberOfQuestions" className="mr-2 my-2">
            <h5>Number of Questions</h5>
          </label>
          <input
            type="number"
            className="form-control custom-input-width"
            id="numberOfQuestions"
            value={numberOfQuestions}
            onChange={onNumberOfQuestionsChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="noOfOptions" className="mr-2 my-2">
            <h5>Number of Options</h5>
          </label>
          <select
            className="form-control custom-input-width"
            id="noOfOptions"
            value={noOfOptions}
            onChange={onNoOfOptionsChange}
          >
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
          </select>
        </div>
        <div>
          <button
            className="btn btn-primary my-2"
            style={{ width: "15vh", marginRight: "2vh" }}
            onClick={onBack}
          >
            Back
          </button>
          <button
            className="btn btn-primary my-2"
            style={{ width: "15vh" }}
            onClick={onNext}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
}

export default TestConfiguration;
